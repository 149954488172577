import permissions from "constants/permissions"
import { useAccess } from "components/permissions/Access"
import { isLoggedInOrganizationThinkingphones } from "lib/user-session"
import { stringify } from "query-string"
import { getDefaultRequestStatus } from "./data/convertStatuses"
import { useCustomerLocation, US } from "hooks/useCustomerLocation"

const didRequestsPath = "/dids/requests"

const buildDidRequestsPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: getDefaultRequestStatus()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${didRequestsPath}?${serializedQueryParams}`
}

const useDIDsAccess = () => {
  const isCustomerHavingUSLocation = useCustomerLocation(US)
  const [hasDidsPermissions, path] = useAccess([permissions.dids.viewDids], buildDidRequestsPath())
  const [hasNoDidRestrictions] = useAccess([permissions.dids.didRequestCreateNoRestrictions])

  const canUserSeeNumberRequests =
    !isLoggedInOrganizationThinkingphones() &&
    (hasNoDidRestrictions || isCustomerHavingUSLocation) &&
    hasDidsPermissions

  return [canUserSeeNumberRequests, path]
}

export { useDIDsAccess }
